import React from 'react'
import Svg from '../Svg'
import { SvgProps } from '../types'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 21">
      <path d="M13.825 7.658L10 11.475 6.175 7.658 5 8.833l5 5 5-5-1.175-1.175z" />
    </Svg>
  )
}

export default Icon
