import React from 'react'
import { useTheme } from 'styled-components'
import Heading from '../../components/Heading/Heading'
import getThemeValue from '../../util/getThemeValue'
import {
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalContainer,
  ModalCloseButton,
  ModalBackButton,
  ClosePosition,
} from './styles'
import { ModalProps } from './types'

const Modal: React.FC<ModalProps> = ({
  title,
  onDismiss,
  onBack,
  children,
  hideCloseButton = false,
  bodyPadding = '24px',
  headerBackground = 'transparent',
  maxWidth = '565px',
  titleAlign = 'left',
  ...props
}) => {
  const theme = useTheme()
  return (
    <ModalContainer maxWidth={maxWidth} {...props}>
      <ModalHeader background={getThemeValue(`colors.${headerBackground}`, headerBackground)(theme)}>
        <ModalTitle>
          {onBack && <ModalBackButton onBack={onBack} />}
          <Heading textAlign={titleAlign} color="var(--white)" scale="lg" style={{ width: '100%' }}>
            {title}
          </Heading>
        </ModalTitle>
        {!hideCloseButton && (
          <ClosePosition>
            <ModalCloseButton onDismiss={onDismiss} />
          </ClosePosition>
        )}
      </ModalHeader>
      <ModalBody p={bodyPadding}>{children}</ModalBody>
    </ModalContainer>
  )
}

export default Modal
